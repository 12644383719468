import { Cart } from "@medusajs/medusa"
import { EnrichedMedusaProduct } from "gatsby-node"
import { getSizingUnit } from "./getSizingUnit"

export const getSizePickerTitle = ({
  recommendedSizes,
  cart,
  hasCm,
  selectedColor,
  isShoes,
  selectedVariant,
}: {
  cart: Readonly<Cart>
  hasCm: boolean
  selectedColor: EnrichedMedusaProduct
  isShoes: boolean
  selectedVariant: any
  recommendedSizes: any
}) => {
  let title = "Size"

  if (recommendedSizes.length > 0) {
    return `Sizes (${getSizingUnit(cart)}) recommended based on your region: `
  }

  if (hasCm) {
    if (selectedVariant?.title) {
      title = `Size (${getSizingUnit(cart)}): `
    } else {
      title = `Size (${getSizingUnit(cart)})`
    }

    return title
  }

  if (selectedColor?.isGiftcard) {
    return "Select amount:"
  }

  if (isShoes) {
    if (selectedVariant?.title) {
      title = "Size (EU): "
    } else {
      title = "Size (EU)"
    }

    return title
  }

  if (
    selectedColor?.type?.value?.startsWith("Gift set") &&
    selectedColor?.metadata?.size_contextual_help
  ) {
    return selectedColor?.metadata?.size_contextual_help?.toString()
  }

  if (selectedVariant?.title) {
    title = "Size: "
  }

  return title
}
