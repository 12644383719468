import * as React from "react"
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Paragraph,
  ThemeUICSSObject,
} from "theme-ui"

export interface ColorPickerItemProps extends BoxProps {
  color?: string
  image?: React.ReactNode
  isActive?: boolean
  isLast?: boolean
}

export const ColorPickerItem: React.FC<ColorPickerItemProps> = ({
  sx,
  color,
  image,
  isActive,
  isLast,
  ...boxProps
}) => {
  return (
    <Box
      {...boxProps}
      sx={{
        ...sx,
        width: isLast ? [18, 8] : [10, 8],
        flexBasis: isLast ? [18, 8] : [10, 8],
        flexShrink: 0,
        flexGrow: 0,
        paddingInline: 1,
        paddingBlockEnd: 1,
        "&:hover .underline": {
          borderBlockEndColor: [null, "primary"],
        },
      }}
    >
      <Box
        sx={{
          height: [8, 6],
          border: "1px solid",
          borderColor: "grayscale.300",
          backgroundColor: color,
          cursor: "pointer",
          marginInlineEnd: isLast && [8, 0],
          marginBlockEnd: 1,
          img: {
            height: "100%",
            width: "100%",
            objectFit: "cover",
          },
        }}
      >
        {image}
      </Box>
      <Box
        className="underline"
        sx={{
          width: [8, 6],
          borderBlockEnd: "1px solid",
          borderBlockEndColor: isActive ? "primary" : "transparent",
          transition: "border-block-end-color .2s",
        }}
      />
    </Box>
  )
}

export interface ColorPickerProps extends FlexProps {
  label?: string
  labelStyles?: ThemeUICSSObject
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  children,
  label,
  labelStyles,
  sx,
  ...boxProps
}) => {
  React.useEffect(() => {
    const labels = document.querySelectorAll("[data-color-picker-label]")
    let maxWidth = 0

    labels.forEach((label: HTMLElement) => {
      const labelWidth = label.offsetWidth
      if (labelWidth > maxWidth) {
        maxWidth = labelWidth
      }
    })

    labels.forEach((label: HTMLElement) => {
      label.style.width = `${maxWidth + 1}px`
    })
  }, [])

  return (
    <Box
      {...boxProps}
      sx={{
        ...sx,
        position: "relative",
        "::after": {
          content: '""',
          display: [null, "none"],
          width: [9, 13],
          height: "100%",
          position: "absolute",
          top: 0,
          right: 0,
          pointerEvents: "none",
          background:
            "linear-gradient(270deg, rgba(246, 246, 246, 1) 0%, rgba(246, 246, 246, 0) 100%)",
        },
      }}
    >
      <Flex
        sx={{
          gap: 2,
          alignItems: ["center", "unset"],
          overflowX: ["scroll", "unset"],
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {label && (
          <Paragraph
            data-color-picker-label
            sx={{
              ...labelStyles,
              flexGrow: 0,
              flexShrink: 0,
              fontSize: ["xs", "sm"],
              marginBlockEnd: [1, 0],
              marginTop: [0, "2px"],
            }}
          >
            {label}
          </Paragraph>
        )}
        <Flex sx={{ flexWrap: [null, "wrap"] }}>{children}</Flex>
      </Flex>
    </Box>
  )
}
