import * as React from "react"
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query"
import { useMedusa } from "medusa-react"

import { Input } from "../ui/Input"

import { formatMoneyAmount, normalizeAmount } from "../../../utils/prices"
import { useStore } from "../../../context/NewStoreContext"

export interface GetCustomGiftCardLimitsReq {
  currency_code: string
}

export interface CreateCustomGiftCardReq {
  amount: number
}

export const useGetCustomGiftCardLimits = ({
  cartId,
  regionId,
}: {
  cartId: string
  regionId: string
}) => {
  const { client: medusa } = useMedusa()

  return useQuery(
    ["custom-gift-card-limits", cartId, regionId],
    () =>
      medusa.client.request(
        "GET",
        `/store/carts/${cartId}/custom-gift-card-limits`
      ),
    {
      enabled: Boolean(cartId),
      keepPreviousData: true,
    }
  )
}

export const useCreateCustomGiftCard = ({ cartId }: { cartId: string }) => {
  const { client: medusa } = useMedusa()
  const queryClient = useQueryClient()

  return useMutation(
    ["custom-gift-card", cartId],
    async (payload: CreateCustomGiftCardReq) => {
      const data = await medusa.client.request(
        "POST",
        `/store/carts/${cartId}/custom-gift-card`,
        payload
      )

      await queryClient.invalidateQueries([cartId])
      return data
    }
  )
}

export const CustomGiftCard: React.FC<{
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  inputRef: React.RefObject<HTMLInputElement>
  hasError: boolean
  setHasError: (hasError: boolean) => void
}> = ({ value, setValue, inputRef, hasError, setHasError }) => {
  const store = useStore()
  const { data } = useGetCustomGiftCardLimits({
    cartId: store.cart?.id,
    regionId: store.cart?.region_id,
  })

  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={(e) => {
        const num = parseInt(e.target.value, 10)

        setValue(e.target.value)

        if (
          !isNaN(num) &&
          num >
            (normalizeAmount(store.cart?.region?.currency_code, data?.min) ||
              0) &&
          num <
            (normalizeAmount(store.cart?.region?.currency_code, data?.max) || 0)
        ) {
          setHasError(false)
        }
      }}
      onBlur={(e) => {
        const num = parseInt(e.target.value, 10)

        if (
          isNaN(num) ||
          num <
            (normalizeAmount(store.cart?.region?.currency_code, data?.min) ||
              0) ||
          num >
            (normalizeAmount(store.cart?.region?.currency_code, data?.max) || 0)
        ) {
          setHasError(true)
        } else {
          setHasError(false)
        }
      }}
      type="number"
      hasError={hasError}
      placeholder={`Amount (${formatMoneyAmount({
        amount: 0,
        currency_code: store.cart?.region?.currency_code,
      })
        .replace("0", "")
        .trimStart()})`}
      inputSx={{ backgroundColor: "transparent" }}
      hasFloatingLabel
      subLabel={
        hasError &&
        `Insert amount between ${formatMoneyAmount({
          amount: data?.min,
          currency_code: store.cart?.region?.currency_code,
        })} - ${formatMoneyAmount({
          amount: data?.max,
          currency_code: store.cart?.region?.currency_code,
        })} ${store.cart?.region?.currency_code?.toUpperCase()}.`
      }
    />
  )
}
