import * as React from "react"
import {
  Flex,
  FlexProps,
  Paragraph,
  ParagraphProps,
  ThemeUIStyleObject,
} from "theme-ui"
import { Link } from "../Link"

export interface SizePickerItemProps extends ParagraphProps {
  isActive?: boolean
  hasDot?: boolean
  disabled?: boolean
  paragraphSx?: ThemeUIStyleObject
}

export const SizePickerItem: React.FC<SizePickerItemProps> = ({
  children,
  sx,
  isActive,
  hasDot,
  disabled,
  paragraphSx,
  ...paragraphProps
}) => {
  return (
    <Paragraph {...paragraphProps} sx={{ ...paragraphSx }}>
      <Link
        sx={{
          fontSize: "md",
          color: disabled ? "grayscale.500" : "inherit",
          textDecoration: disabled && "line-through",
          position: "relative",
          borderBlockEnd: "1px solid",
          borderBlockEndColor: isActive ? "currentColor" : "transparent",
          pointerEvents: isActive ? "none" : "unset",

          "::after": {
            content: hasDot && "''",
            width: 1,
            height: 1,
            backgroundColor: "grayscale.400",
            borderRadius: "100%",
            position: "absolute",
            top: -1,
            right: -1,
          },

          ...sx,
        }}
      >
        {children}
      </Link>
    </Paragraph>
  )
}

export const SizePicker: React.FC<FlexProps> = ({
  children,
  sx,
  ...flexProps
}) => {
  return (
    <Flex {...flexProps} sx={{ ...sx, alignItems: "center" }}>
      {children}
    </Flex>
  )
}
