import { useQuery } from "@tanstack/react-query"
import medusaRequest from "../../services/request"
import { AxiosResponse } from "axios"
import { Product } from "@medusajs/medusa"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"

type RequestConfig = {
  limit?: number
  offset?: number
  relations?: string[]
}

export type ColorResponse = {
  color: Color
}

export type ColorsResponse = {
  colors: Color[]
  count: number
}

export type ProductColorsResponse = {
  products: PricedProduct[]
}

export type Color = {
  id: string
  title: string
  hex?: string
  color_group?: string[]
  pattern?: string
  image?: {
    id: string
    url: string
  }
  products: Product[]
  metadata: {
    [key: string]: string
  }
}

export const retrieveColors = async (
  config?: RequestConfig
): Promise<{ colors: Color[]; count?: number }> => {
  const data: AxiosResponse<ColorsResponse> = await medusaRequest(
    "GET",
    "store/colors",
    {
      params: {
        limit: config?.limit || 15,
        offset: config?.offset || 0,
        relations: config?.relations || [],
      },
    }
  )

  if (data.data.colors) {
    return { colors: data.data.colors, count: data.data.count }
  }

  return { colors: [], count: 0 }
}

export const retrieveRelatedProducts = async (
  productId: string,
  regionId: string
): Promise<{ products: PricedProduct[] }> => {
  if (!productId) {
    return { products: [] }
  }

  const { data }: AxiosResponse<ProductColorsResponse> = await medusaRequest(
    "POST",
    `store/products/${productId}/related-products`,
    {
      region_id: regionId,
    }
  )

  if (data.products) {
    return { products: data.products }
  }

  return { products: [] }
}

export const retrieveProductColor = async (
  productId: string,
  config?: RequestConfig
): Promise<{ color: Color | undefined; count?: number }> => {
  if (!productId) {
    return { color: undefined }
  }
  const data: AxiosResponse<ColorResponse> = await medusaRequest(
    "GET",
    `store/colors/${productId}`
  )

  if (data.data.color) {
    return { color: data.data.color }
  }

  return { color: undefined }
}

export const useColors = (config?: RequestConfig) => {
  const { data, ...rest } = useQuery(
    ["colors", config?.offset],
    () => retrieveColors(config),
    {
      keepPreviousData: true,
    }
  )
  return { ...data, ...rest } as const
}

export const useProductColor = (productId: string) => {
  const { data, ...rest } = useQuery(
    ["product-color", productId],
    () => retrieveProductColor(productId),
    { keepPreviousData: true }
  )

  return { ...data, ...rest } as const
}

export const useRelatedProducts = (productId: string, regionId: string) => {
  const { data, ...rest } = useQuery(
    ["related-products", productId, regionId],
    () => retrieveRelatedProducts(productId, regionId),
    {
      enabled: Boolean(productId),
      keepPreviousData: true,
    }
  )

  return { ...data, ...rest } as const
}
