import { SelectedVariant } from "../../product"

export const getShoesSize = (
  variant: Pick<SelectedVariant, "title" | "metadata">,
  countryCode: string
) => {
  if (!variant) {
    return
  }

  let size

  if (countryCode === "gb" || countryCode === "us" || countryCode === "jp") {
    size = variant?.metadata?.["size_" + countryCode]
  }

  return size ?? variant.title
}
