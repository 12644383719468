import * as React from "react"

// External packages
import { navigate } from "gatsby"
import { Box, Paragraph } from "theme-ui"

// Utilities
import {
  addToWishlistLoggedOut,
  isInWishlist,
} from "../../../utils/functions/product-util"
import { trackAddToWishlist } from "../../../services/analytics"

// Hooks
import { useAccountContext } from "../../../context/AccountContext"
import { useNotificationContext } from "../../../context/NotificationContext"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { useStore } from "../../../context/NewStoreContext"

// Components
import { Link } from "../Link"
import { Button } from "../../v2/Button"
import { Icon } from "../ui/Icon"

// Types
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { ProductVariant } from "@medusajs/medusa"

type AddToWishlistProps = {
  variant: ProductVariant | PricedVariant
  productTitle: string
  onAdd?: () => void
}

export const AddToWishlist: React.FC<AddToWishlistProps> = ({
  variant,
  productTitle,
  onAdd,
}) => {
  const { loggedIn, addToWishList, id, removeFromWishList, metadata } =
    useAccountContext()
  const { pushNotification, dismissNotification } = useNotificationContext()
  const { setWishlistCount } = useInterfaceContext()
  const { cart } = useStore()

  const handleAddToWishlist = async () => {
    let wishlist = []
    let userData = {}

    // No variant selected
    if (!variant.id) {
      pushNotification({
        id: "no-selection",
        body: "Please select a size",
        dismiss: {
          duration: 3000,
        },
      })
      return
    }

    if (loggedIn) {
      let res
      // If item in wishlist, remove it.
      if (isInWishlist(variant, metadata)) {
        res = await removeFromWishList(variant.id)
      } else {
        // Add to wishlist
        res = await addToWishList({
          variantId: variant.id,
          config: {
            userId: id,
          },
        })
      }
      userData = { id: id, email: res?.customer?.email }
      wishlist = res?.customer?.metadata?.wishlist
    } else {
      // Add to wishlist in local storage for logged out user
      wishlist = addToWishlistLoggedOut({
        id: variant.id,
      })
    }

    pushNotification({
      id: "wishlist-added",
      body: (
        <>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            {wishlist
              ?.map((item) => (loggedIn ? item.variant_id : item.variantId))
              ?.includes(variant.id)
              ? "Added to wishlist"
              : "Removed from wishlist"}
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {wishlist
              ?.map((item) => (loggedIn ? item.variant_id : item.variantId))
              ?.includes(variant.id)
              ? `${productTitle} - ${variant?.title} added to wishlist`
              : `${productTitle} - ${variant?.title} removed from wishlist`}
          </Paragraph>
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("wishlist-added")
              navigate("/wishlist")
            }}
          >
            See the wishlist
          </Button>
        </>
      ),
      dismiss: {
        duration: 5000,
      },
    })

    trackAddToWishlist({
      productTitle: productTitle,
      variant: variant,
      cart: cart,
      userData: userData,
    })

    setWishlistCount(wishlist?.length)
  }

  return (
    <Link
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        handleAddToWishlist()
        if (onAdd) {
          onAdd()
        }
      }}
    >
      <Box as="span">
        {isInWishlist(variant, metadata)
          ? "Added to wishlist"
          : "Add to wishlist"}
      </Box>
      <Icon
        name={isInWishlist(variant, metadata) ? "heart-filled" : "heart-empty"}
        size={6}
        sx={{ marginInlineStart: 2 }}
      />
    </Link>
  )
}
