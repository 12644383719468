import * as React from "react"

export function useInView(refs: Element[], options?: IntersectionObserverInit) {
  const [elements, setElements] = React.useState<{
    [key: string]: { isInView: boolean }
  }>({})

  React.useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const name = entry.target.getAttribute("data-observer-id")
        if (!name) {
          console.warn(
            "Encountered entry with no name. You should add 'data-observer-id' to every element passed to the isInView hook."
          )
        } else {
          if (entry.isIntersecting) {
            setElements((prev) => {
              return {
                ...prev,
                [name]: {
                  isInView: true,
                },
              }
            })
          } else {
            setElements((prev) => ({
              ...prev,
              [name]: {
                isInView: false,
              },
            }))
          }
        }
      })
    }

    const observer = new IntersectionObserver(observerCallback, options)

    refs.forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      observer.disconnect()
    }
  }, [refs])

  return elements
}
