// External packages
import * as React from "react"
import { Box, Flex, Paragraph } from "theme-ui"
import { useSwipeable } from "react-swipeable"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Components
import { Icon } from "../ui/Icon"
import { Link } from "../Link"

import { DrawerProps } from "../ui/Drawer"

interface GiftSetsInfoDrawerProps extends DrawerProps {
  information?: any
  optionsRichText?: any
}

export const GiftSetsInfoDrawer: React.FC<GiftSetsInfoDrawerProps> = ({
  information,
  optionsRichText,
  isOpened,
  size = "base",
  position = "right",
  padding = "base",
  hasBlur = true,
  hasBlurOnMobile = true,
  onCloseClick,
  onBackdropClick,
  onSwipeRight,
  sx,
  ...flexProps
}) => {
  const swipeHandlers = useSwipeable({
    onSwipedRight: onSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <>
      <Flex
        {...swipeHandlers}
        {...flexProps}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: (position === "left" || position === "right") && 157,
          width: "100%",
          maxHeight: "100dvh",
          height: (position === "left" || position === "right") && "100vh",
          opacity: isOpened ? 1 : 0,
          visibility: isOpened ? "visible" : "hidden",
          fontSize: "sm",
          backgroundColor: "grayscale.white",
          position: "fixed",
          top:
            (position === "top" ||
              position === "left" ||
              position === "right") &&
            0,
          right: position === "right" && 0,
          bottom: position === "bottom" && 0,
          left:
            (position === "left" ||
              position === "top" ||
              position === "bottom") &&
            0,
          zIndex: "productDrawer",
          transform:
            position === "left"
              ? isOpened
                ? "translateX(0)"
                : "translateX(-100%)"
              : position === "right"
              ? isOpened
                ? "translateX(0)"
                : "translateX(100%)"
              : position === "top"
              ? isOpened
                ? "translateY(0)"
                : "translateY(-100%)"
              : position === "bottom"
              ? isOpened
                ? "translateY(0)"
                : "translateY(100%)"
              : "none",
          transition: isOpened
            ? "transform .9s cubic-bezier(.5, .5, 0, 1), opacity 10ms, visibility .9s"
            : "transform .9s cubic-bezier(.5, .5, 0, 1), opacity 10ms .9s, visibility .9s .9s",
          ...sx,
        }}
      >
        <Link
          onClick={onCloseClick}
          sx={{
            width: 7,
            height: 7,
            position: "absolute",
            top: padding === "base" ? 4 : 6,
            right: 4,
          }}
        >
          <Icon name="x" size={5} />
        </Link>
        <Box sx={{ width: "100%", overflowY: "scroll", height: "100%" }}>
          <Box
            sx={{
              height: "100%",
              paddingInline: padding === "base" ? 9 : 4,
              paddingBlockStart: padding === "base" ? 10 : 6,
              paddingBlockEnd: padding === "base" ? 10 : 7,
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                maxWidth: size === "lg" ? 92 : size === "xl" ? 126 : 89,
                marginInline: "auto",
              }}
            >
              {information.body.length > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 4,
                    rowGap: 2,
                    flexShrink: 0,
                    overflow: "auto",
                    paddingBlock: 4,
                    marginBlockEnd: 4,
                  }}
                >
                  {information.body.map((i, index) => (
                    <Link
                      variant="underline"
                      key={index}
                      onClick={() => setTabIndex(index)}
                      sx={{
                        flexShrink: 0,
                        borderColor:
                          tabIndex === index ? "currentcolor" : "transparent",
                        "&:hover": {
                          borderColor:
                            tabIndex === index ? "currentcolor" : "transparent",
                        },
                      }}
                    >
                      {i.title}
                    </Link>
                  ))}
                </Box>
              )}
              {information.body && (
                <Box
                  sx={{
                    marginBlock: "auto",
                    ul: {
                      listStyleType: "none",
                      marginInline: 0,
                      marginBlock: 6,
                    },
                    a: {
                      color: "currentcolor",
                    },
                  }}
                >
                  {information.body.length > 1
                    ? information.body.map((child, index) => (
                        <Paragraph
                          sx={{
                            display: tabIndex === index ? "block" : "none",
                          }}
                        >
                          {renderRichText(child, optionsRichText)}
                        </Paragraph>
                      ))
                    : information.body.length
                    ? renderRichText(information.body[0], optionsRichText)
                    : renderRichText(information.body, optionsRichText)}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box
        onClick={() => onBackdropClick()}
        sx={{
          width: "100%",
          height: "100%",
          opacity: isOpened ? 1 : 0,
          visibility: isOpened ? "visible" : "hidden",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: "dismissCatch",
          backdropFilter:
            hasBlur && hasBlurOnMobile
              ? "blur(15px)"
              : hasBlur
              ? ["none", "blur(15px)"]
              : "none",
          transition: "opacity .6s, visibility .6s",
        }}
      />
    </>
  )
}
