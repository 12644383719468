import React, { useEffect, useState } from "react"

import ContentModules from "../../components/Page/ContentModules"
import Product from "../../components/v2/product"
import { PageProps } from "gatsby"
import { ProductCategory } from "@medusajs/medusa"
import {
  ContentfulCollectionProduct,
  EnrichedMedusaProduct,
} from "../../../gatsby-node"
import {
  isContentfulGrid,
  isContentfulImageGallery,
  isContentfulText,
} from "../../components/v2/contentful/utils"
import { Grid } from "../../components/v2/contentful/Grid"
import { Text } from "../../components/v2/contentful/Text"
import { ImageGallery } from "../../components/v2/contentful/ImageGallery"

export interface ContentfulSizeGuide {
  title: string
  medusaCollectionHandles: string[]
  medusaProductTypes: string[]
  sizeGuides: {
    gender: string
    id: string
    title: string
    text: {
      childMarkdownRemark: unknown
    }
    body: {
      raw: string
      references: unknown[]
    }
  }[]
}

interface ProductPageTemplateProps {
  category: Pick<ProductCategory, "handle" | "id" | "name">
  collection: Pick<ProductCategory, "handle" | "id" | "name" | "metadata">
  product: EnrichedMedusaProduct
  size_guides: ContentfulSizeGuide[]
  type: string
  subType: string
  products: Pick<
    ContentfulCollectionProduct,
    | "color"
    | "id"
    | "handle"
    | "imagePacks"
    | "thumbnail"
    | "disableNotifyMe"
    | "sku"
    | "title"
    | "isGiftcard"
    | "variants"
  >[]
  shopstoryContent: unknown
  giftSetProducts?: Pick<
    EnrichedMedusaProduct,
    "id" | "imagePacks" | "disableNotifyMe" | "information"
  >[]
  seo: {
    breadcrumbsList: {
      "@type": string
      position: number
      name: string
      item: string
    }[]
  }
}

const ProductPageTemplate = ({
  pageContext,
  location,
}: PageProps<object, ProductPageTemplateProps>) => {
  const {
    category,
    collection,
    product,
    products,
    size_guides,
    giftSetProducts,
    seo,
  } = pageContext
  const preSelectedSize = location?.state?.size

  const handleContentModules = () => {
    // check if shopStoryContent is empty object
    const hasShopstory = Object.keys(pageContext.shopstoryContent).length > 0
    if (!product.contentModules?.length) {
      return null
    }

    return (
      <div className="content-module">
        {product.contentModules
          ?.filter((i) => i.internal)
          .map((item) => {
            if (isContentfulGrid(item)) {
              return <Grid key={item.id} data={item} />
            }
            if (isContentfulText(item)) {
              return <Text key={item.id} data={item} />
            }
            if (isContentfulImageGallery(item)) {
              return <ImageGallery key={item.id} data={item} />
            }

            // TODO: Delete ContentModules component when content is migrated to new modules
            if (hasShopstory) {
              return (
                <ContentModules
                  allModules={product.contentModules}
                  shopstoryContent={pageContext.shopstoryContent}
                />
              )
            }
            return null
          })}
      </div>
    )
  }

  return (
    <>
      {product && (
        <Product
          category={category}
          sizeGuideGroups={size_guides}
          selectedColor={product}
          colorOptions={products}
          collection={collection}
          information={product?.information}
          seo={seo}
          contentModules={handleContentModules()}
          preselectedSize={preSelectedSize}
          giftSetProducts={giftSetProducts || []}
        />
      )}
    </>
  )
}

export default ProductPageTemplate
